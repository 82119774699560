import { facebookGroupUrl } from "assets/constants/links";

const content = {
  heading: "Åk Tillsammans",
  paragraphs: [
    "Har du en bil eller behöver du kanske skjuts i en? I facebook-gruppen på länken nedan kan du vara med och prata om just detta!",
  ],
  buttonProps: {
    text: "Samåkning",
    icon: "car",
    href: facebookGroupUrl,
  },
};

export default content;
