import React from "react";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IconButton = props => {
  const { icon, text } = props;
  return (
    <Button
      className="my-button"
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      <FontAwesomeIcon className="icon" icon={icon} />
      <strong>{text}</strong>
    </Button>
  );
};

export default IconButton;
