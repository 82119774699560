import React from "react";

import OmniBox from "components/OmniBox";

import content from "./content";

const LannaBox = props => {
  return <OmniBox {...content} className="m-auto reorder-1" />;
};

export default LannaBox;
