import { osaMailtoUrl } from "assets/constants/email";

const content = {
  heading: "O.S.A. senast 15 juni",
  buttonProps: {
    text: "Skicka svar",
    icon: "paper-plane",
    href: osaMailtoUrl,
  },
};

export default content;
