import React from "react";
import Child from "components/Child";
import IconButton from "components/IconButton";

// Displays a heading, an optional set of paragraphs and a configurable button
const OmniBox = props => {
  const { heading, paragraphs, buttonProps, classNames, ...otherProps } = props;
  return (
    <Child className={classNames} {...otherProps}>
      <h3>{heading}</h3>
      {paragraphs &&
        paragraphs.map(p => (
          <p className="grow" key={p}>
            {p}
          </p>
        ))}
      {buttonProps && <IconButton {...buttonProps} />}
    </Child>
  );
};

export default OmniBox;
