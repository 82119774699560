import React from "react";

const Container = props => {
  const { children, className: otherClassnames, ...otherProps } = props;
  const ownClassnames = ["horizontal-container"];
  const classNames = [ownClassnames, otherClassnames].join(" ");

  return (
    <div className={classNames} {...otherProps}>
      {children}
    </div>
  );
};

export default Container;
