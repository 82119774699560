import { helpListUrl } from "assets/constants/links";

const content = {
  heading: "Hjälp till!",
  paragraphs: [
    "Festen blir roligare om vi gör den tillammans. Vi kommer att behöva hjälp med olika saker inför bröllopsfesten. Vill du laga mat eller pynta festlokalen? Skriv upp dig i listan nedan!",
  ],
  buttonProps: {
    text: "Hjälplistan",
    icon: "hands-helping",
    href: helpListUrl,
  },
};

export default content;
