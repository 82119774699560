import React from "react";

const Welcome = () => {
  return (
    <div className="welcome">
      <h2>Välkomna till bröllop!</h2>
      <p>
        Den 21 september smäller det! På den här sidan hittar du information om
        vår dag och hur du tar dig till olika ställen. Nedan hittar du även
        listor som kan hjälpa dig att samåka till festen, få inpiration till
        bröllopsgåva och dessutom en gyllene chans att hjälpa oss göra den bästa
        festen ever!
      </p>
    </div>
  );
};

export default Welcome;
