import { giftListUrl } from "assets/constants/links";

const content = {
  heading: "Gåvolista",
  paragraphs: [
    "På länken nedan kan du se förslag på vad brudparet önskar sig i bröllopspresent. För att skriva upp dig på en gåva, eller lägga till en egen, gör du det i kolumnerna i dokumentet.",
  ],
  buttonProps: {
    text: "Gåvolistan",
    icon: "gift",
    href: giftListUrl,
  },
};

export default content;
