import React from "react";

import InfoBox from "components/InfoBox";
import IconButton from "components/IconButton";

import { toastMailtoUrl } from "assets/constants/email";

const ToastBox = props => {
  return (
    <InfoBox heading="Sång & Toast">
      <p>
        Har du frågor eller vill anmäla tal, spex eller något annat? Maila våra
        fantastiska toastmasters, Emilia och Linus.
      </p>
      <IconButton
        href={toastMailtoUrl}
        icon="glass-cheers"
        text="Toastmasters"
      />
    </InfoBox>
  );
};

export default ToastBox;
