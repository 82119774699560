import { googleMapsUrl } from "assets/constants/links";

const content = {
  heading: "Hitta till Länna Gård",
  paragraphs: [
    "Länna Gård ligger ca 15 km utanför Uppsala. Från Uppsala kör man väg 282 mot Almunge. När man passerat igenom Länna ser man den stora gula herrgården framför sig, då tar man av till vänster in på grusvägen innan väg 282 fortsätter åt höger. Fortsätt på grusvägen ca 300 meter så når du parkeringen bakom den stora ladan där festlokalen är.",
    // "Buss 809 mot Knutby går regelbundet från Uppsala resecentrum och stannar vid hållplats Länna Gård. Resan med buss tar ca 25 minuter.",
  ],
  buttonProps: {
    text: "Hitta på Google Maps",
    icon: "map-marked-alt",
    href: googleMapsUrl,
  },
};

export default content;
