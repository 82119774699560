import React from "react";

import content from "./content";
import OmniBox from "components/OmniBox/OmniBox";

const CarBox = props => {
  return <OmniBox {...content} />;
};

export default CarBox;
