import React from "react";

import content from "./content";
import OmniBox from "components/OmniBox/OmniBox";

const OsaBox = ({ onClick }) => {
  // OSA Button should not open a new tab
  Object.assign(content.buttonProps, {
    onClick,
    rel: "",
    target: "",
  });

  return <OmniBox {...content} />;
};

export default OsaBox;

// const OsaBox = ({ onClick }) => {
//   return (
//     <Child>
//       <h3>O.S.A. senast 15 juni</h3>
//       <IconButton
//         href={osaMailtoUrl}
//         icon="paper-plane"
//         text="Skicka svar"
//         onClick={onClick}
//       />
//     </Child>
//   );
// };
