import React from "react";
import Footer from "components/Footer";
import Home from "pages/Home";

import { logo } from "assets/images";

/* eslint no-unused-vars: 0 */
import icons from "assets/icons";

function App() {
  return (
    <div className="app-container">
      <div className="header">
        <img className="logo" src={logo} alt="Logo" />
      </div>
      <div className="my-content">
        <Home />
      </div>
      <Footer />
    </div>
  );
}

export default App;
