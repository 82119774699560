import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { instagramUrl } from "assets/constants/links";

const HashtagBox = props => {
  return (
    <div className="hashtag-container">
      <p>
        <a href={instagramUrl} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon
            className="instagram-icon"
            icon={["fab", "instagram"]}
          />
        </a>
      </p>
      <a
        className="hashtag"
        href={instagramUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        #helenochsebastian
      </a>
    </div>
  );
};

export default HashtagBox;
