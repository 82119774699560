import React from "react";

import Container from "components/Container";

import Welcome from "./components/Welcome";
import OsaBox from "./components/OsaBox";
import ToastBox from "./components/ToastBox";
import HeaderImage from "./components/HeaderImage";
import ChurchBox from "./components/ChurchBox";
import LannaBox from "./components/LannaBox";
import MapBox from "./components/MapBox";
import HashtagBox from "./components/HashtagBox";

import HelpBox from "./components/LinksGroup/HelpBox";
import CarBox from "./components/LinksGroup/CarBox";
import GiftBox from "./components/LinksGroup/GiftBox";

import { church, lanna } from "assets/images";
import MapImage from "./components/MapImage";

const Home = () => {
  return (
    <React.Fragment>
      <HeaderImage />
      <div className="home-content">
        <Welcome />
        <Container>
          <OsaBox />
        </Container>
        <Container>
          <ToastBox />
        </Container>

        <Container>
          <GiftBox />
          <HelpBox />
          <CarBox />
        </Container>
        <Container className="mb-lg">
          <div className="horizontal-child-img m-auto">
            <img src={church} alt="Gamla Uppsala Kyrka" />
          </div>
          <ChurchBox />
        </Container>
        <Container className="mb-lg">
          <div className="horizontal-child-img m-auto reorder-2">
            <img src={lanna} alt="Länna Gård" />
          </div>
          <LannaBox />
        </Container>
        <Container className="mb-lg">
          <div className="horizontal-child-img m-auto">
            <MapImage />
          </div>
          <MapBox />
        </Container>
        <HashtagBox />
      </div>
    </React.Fragment>
  );
};

export default Home;
