import React from "react";

import { headerImageSm, headerImageLg } from "assets/images";

const HeaderImage = () => {
  return (
    <img
      className="header-image"
      src={headerImageSm}
      srcSet={`${headerImageLg} 1920w, ${headerImageSm} 960w`}
      sizes="(min-width: 960px) 960px, 100vw"
      alt="Brudparet"
    />
  );
};

export default HeaderImage;
