import React from "react";

const InfoBox = props => {
  const { heading, ...rest } = props;
  return (
    <div className="horizontal-child" {...rest}>
      <h3>{heading}</h3>
      {props.children}
    </div>
  );
};

export default InfoBox;

//           <div className="horizontal-child">
//             <h3>Sång & Toast</h3>
//             <p>
//               Har du frågor eller vill anmäla tal, spex eller något annat? Maila
//               våra fantastiska toastmasters, Emilia och Linus.
//             </p>
//             <IconButton
//               href={toastMailtoUrl}
//               icon="glass-cheers"
//               text="Toastmasters"
//             />
//           </div>
