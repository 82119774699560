import React from "react";

import OmniBox from "components/OmniBox";

import content from "./content";

const ChurchBox = props => {
  return <OmniBox {...content} className="m-auto" />;
};

export default ChurchBox;
