import React from "react";

const Child = props => {
  const { children, className, ...otherProps } = props;
  const classNames = ["horizontal-child", className].join(" ");
  return (
    <div className={classNames} {...otherProps}>
      {children}
    </div>
  );
};

export default Child;
